// This directive is used to replace the `hidden-*` bootstrap css classes.
// Instead of hiding elements based on screen size, this will remove them from the DOM completely.
// This will reduce DOM bloat to increase our performance score with Google.
export default defineNuxtPlugin(({ vueApp }) => {
    vueApp.directive("hidden", {
        // Mounted function is called when the bound element has been mounted into its parent node
        mounted(el, binding) {
            if (!binding || !el) {
                return;
            }

            const sizes = binding.value.split(",");
            const screenSize = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

            const hideXs = sizes.indexOf("xs") >= 0,
                hideSm = sizes.indexOf("sm") >= 0,
                hideMd = sizes.indexOf("md") >= 0,
                hideLg = sizes.indexOf("lg") >= 0;

            const tailwindClasses = {
                xs: "max-sm:hidden",
                sm: "sm:max-md:hidden",
                md: "md:max-lg:hidden",
                "lg-1270": "lg:max-lg-1270:hidden",
                lg: "lg:hidden",
            };

            let hide = false;

            if (hideXs && screenSize < SCREEN_BREAKPOINTS.sm) {
                hide = true;
            }

            if (hideSm && screenSize >= SCREEN_BREAKPOINTS.sm && screenSize < SCREEN_BREAKPOINTS.md) {
                hide = true;
            }

            if (hideMd && screenSize >= SCREEN_BREAKPOINTS.md && screenSize < SCREEN_BREAKPOINTS.lg) {
                hide = true;
            }

            if (hideLg && screenSize >= SCREEN_BREAKPOINTS.lg) {
                hide = true;
            }

            // Add bootstrap classes in as well for sizing-down and print view
            for (const size of sizes) {
                el.classList.add(tailwindClasses[size as keyof typeof tailwindClasses]);
            }

            if (hide) {
                el.remove();
            }
        },
    });
});
