<template>
    <NuxtLayout>
        <div class="container mx-auto">
            <div class="p-4 my-8 text-lg bg-danger-100 text-danger-900 rounded">
                {{ message }}
            </div>
            <div v-if="isDev">
                <div class="text-base font-bold">{{ props.error.message }}</div>
                <div v-html="props.error.stack"></div>
            </div>
        </div>
    </NuxtLayout>
</template>
<script setup lang="ts">
import type { NuxtError } from "#app";

const isDev = import.meta.dev;
const { t } = useI18n();
const props = defineProps({
    error: { type: Object as () => NuxtError, default: null },
});

const statusCodeMessage: Record<number, string> = {
    403: "ErrorPage.Forbidden",
    404: "ErrorPage.NotFound",
};

useInitializeState();

useServerHead({
    title: t("Global.TitleDefault"),
});

const message = computed(() => t(statusCodeMessage[props.error?.statusCode] ?? "ErrorPage.Generic"));
</script>
