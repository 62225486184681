import axios from "axios";

// This allows us a single place to customize the axios instance used
// by all of our code
const instance = axios.create({
    xsrfCookieName: "XSRF-TOKEN",
    xsrfHeaderName: "CSRF-Token",
});

export default instance;
