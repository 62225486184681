<template>
    <NuxtLayout>
        <NuxtPage />
        <Toaster />
    </NuxtLayout>
</template>
<script setup>
const client = import.meta.client;
const { t, locale } = useI18n();
const i18nHead = useLocaleHead({ addSeoAttributes: true });
const config = useRuntimeConfig();
const route = useRoute();
const { cdnUrl, enableDatadome } = config.public;

useHead({
    titleTemplate: (title) => {
        if (!title) title = t("Global.TitleDefault");
        return title.includes("TrustedParts.com") ? title : title + " " + t("Global.TitleSuffix");
    },
});

const serverScripts = [];

if (enableDatadome) {
    serverScripts.push({
        children:
            '!function (a, b, c, d, e, f) { a.ddjskey = e; a.ddoptions = f || null; var m = b.createElement(c), n = b.getElementsByTagName(c)[0]; m.async = 1, m.src = d, n.parentNode.insertBefore(m, n);}(window, document, "script", "https://dd.trustedparts.com/tags.js", "7012F816DF6737C9087618574AE3B2", {endpoint: "https://dd.trustedparts.com/js/", ajaxListenerPath : true });',
    });
}

useServerHead({
    htmlAttrs: {
        lang: locale.value,
    },
    link: [
        { rel: "preconnect", href: "https://dd.trustedparts.com" },
        { rel: "apple-touch-icon", sizes: "180x180", href: cdnUrl + "/apple-touch-icon.png" },
        { rel: "manifest", href: cdnUrl + "/site.webmanifest" },
        { rel: "mask-icon", href: cdnUrl + "/safari-pinned-tab.svg", color: "#00274e" },
        ...(i18nHead.value.link || []).filter((link) => link.hid !== "i18n-xd"), // remove default language alternate link
    ],
    meta: [
        { name: "msapplication-TileColor", content: "#00274e" },
        { name: "theme-color", content: "#00274e" },
        ...(i18nHead.value.meta || []),
    ],
    script: serverScripts,
});

// Global SEO tags common to all pages.
useServerSeoMeta({
    ogType: "website",
    ogSiteName: "TrustedParts.com",
    ogImage: cdnUrl + "/assets/images/trusted-parts-logo-home.png",
});

const { userInfo } = await useInitializeState();

const { gtag } = useGtag();
if (!userInfo.value.AcceptsAnalyticsCookies) {
    gtag("consent", "default", { ad_storage: "denied", analytics_storage: "denied" });
}

const cookieConsent = useCookieConsent();
if (client && (userInfo.value.IsCookieConsentNeeded || route.query["load-cookie-consent"])) {
    cookieConsent.init(locale.value);
}
</script>
