import type { UseFetchOptions } from "nuxt/app";
import { hash } from "ohash";
const server = import.meta.server;

export function useFetchApi<T>(url: string | (() => string), options: UseFetchOptions<T> = {}) {
    const xsrfToken = useState("xsrfToken");
    options.headers = useRequestHeaders([
        "cookie",
        "X-Forwarded-For",
        "X-Datadome-IsBot",
        "X-Datadome-Botname",
        "X-Datadome-Botfamily",
        "X-Datadome-Ruletype",
        "user-agent",
    ]);
    options.headers["CSRF-Token"] = xsrfToken.value as string;

    const config = useRuntimeConfig();
    const base = server ? config.apiUrl : config.public.baseUrl;
    return useFetch([base, url].join("/"), {
        ...options,
        // Nuxt's method for calculating the automatic key for the cache doesn't work for us because the
        // full request url is different at the server from the client
        key: options.key || hash([url, generateOptionSegments(options)]),
    });
}

export function useFetchLocaleApi<T>(url: string | (() => string), options: UseFetchOptions<T> = {}) {
    const { locale } = useI18n();

    options.query ??= {};
    Object.assign(options.query, { lang: locale.value });
    return useFetchApi<T>(url, options);
}

// From https://github.com/nuxt/nuxt/blob/2cf12e3cd68a0175e89f0001faf0bb60e4605972/packages/nuxt/src/app/composables/fetch.ts#L242
// allows us to create an object that can uniquely identify the request
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function generateOptionSegments<_ResT, DataT, DefaultT>(opts: UseFetchOptions<_ResT, DataT, any, DefaultT, any, any>) {
    const segments: Array<string | undefined | Record<string, string>> = [
        toValue(opts.method as MaybeRef<string | undefined> | undefined)?.toUpperCase() || "GET",
        toValue(opts.baseURL),
    ];
    for (const _obj of [opts.params || opts.query]) {
        const obj = toValue(_obj);
        if (!obj) {
            continue;
        }

        const unwrapped: Record<string, string> = {};
        for (const [key, value] of Object.entries(obj)) {
            unwrapped[toValue(key)] = toValue(value);
        }
        segments.push(unwrapped);
    }
    return segments;
}
