import * as CookieConsent from "vanilla-cookieconsent";
import translations from "./cookie-strings.json";

export function useCookieConsent() {
    const { gtag } = useGtag();
    const { locale } = useI18n();

    return {
        init() {
            async function setPreferences(categories: string[]) {
                if (localStorage) {
                    const response = await axios.post("/api/cookie-consent", {
                        PerformanceAndAnalytics: categories.includes("analytics"),
                        TargetingAndAdvertising: categories.includes("targeting"),
                    });

                    const newGuid = response.data.Guid;
                    if (newGuid) {
                        localStorage.setItem(
                            "trusted-parts-cookie-consent-accepted-on",
                            new Date().getTime().toString()
                        );
                        localStorage.setItem("trusted-parts-cookie-consent-accepted-guid", newGuid);
                    }
                }
            }

            import("cookieconsent.css");
            CookieConsent.run({
                autoShow: true,
                language: {
                    default: locale.value,
                    translations,
                },
                autoClearCookies: true,
                manageScriptTags: false,
                mode: "opt-out",
                categories: {
                    necessary: {
                        readOnly: true,
                        enabled: true,
                    },
                    analytics: {
                        enabled: true,
                    },
                    targeting: {
                        enabled: true,
                    },
                },

                onFirstConsent: ({ cookie }) => {
                    // Did the user accept all or customize accepted cookies?
                    if (cookie.categories.includes("analytics") && cookie.categories.includes("targeting")) {
                        gtag("event", "cookies_overlay_accept");
                    } else {
                        gtag("event", "cookies_modal_save");

                        if (!cookie.categories.includes("analytics")) {
                            gtag("event", "cookies_modal_disable_analytics");
                        }
                        if (!cookie.categories.includes("targeting")) {
                            gtag("event", "cookies_modal_disable_targeting");
                        }
                    }

                    setPreferences(cookie.categories || []);
                },

                onChange: ({ cookie, changedCategories }) => {
                    // If this was fired as part of the initial interaction, it's not actually a change
                    if (cookie.consentTimestamp === cookie.lastConsentTimestamp) {
                        return;
                    }

                    gtag("event", "cookies_modal_save");

                    if (changedCategories && changedCategories.includes("targeting")) {
                        if (cookie.categories.includes("targeting")) {
                            gtag("event", "cookies_modal_enable_targeting");
                        } else {
                            gtag("event", "cookies_modal_disable_targeting");
                        }
                    }
                    if (changedCategories && changedCategories.includes("analytics")) {
                        if (cookie.categories.includes("analytics")) {
                            gtag("event", "cookies_modal_enable_analytics");
                        } else {
                            gtag("event", "cookies_modal_disable_analytics");
                        }
                    }

                    setPreferences(cookie.categories || []);
                },
            });
        },
    };
}
