<template>
    <ToastProvider>
        <Toast v-for="toast in toasts" :key="toast.id" v-bind="toast">
            <div class="grid gap-1">
                <ToastTitle v-if="toast.title">
                    <span v-html="toast.title"></span>
                </ToastTitle>
                <template v-if="toast.description">
                    <ToastDescription v-if="isVNode(toast.description)">
                        <component :is="toast.description" />
                    </ToastDescription>
                    <ToastDescription v-else>
                        <span v-html="toast.description"></span>
                    </ToastDescription>
                </template>
                <ToastClose />
            </div>
            <component :is="toast.action" />
        </Toast>
        <ToastViewport />
    </ToastProvider>
</template>
<script setup lang="ts">
import { isVNode } from "vue";

const { toasts } = useToast();
</script>
